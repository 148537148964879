/**
 * @flow
 */

import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/layout';
import PropTypes from 'prop-types';
import MarkdownPage from 'components/MarkdownPage';
import { createLinkDocs } from 'utils/createLink';
import {
  sectionListLejer,
  sectionListUdlejer,
  sectionListEnglish
} from 'utils/sectionList';

const Article = ({ data, location }) => (
  <Layout location={location}>
    <MarkdownPage
      enableScrollSync={/^\/en\/?$/.test(location.pathname)}
      createLink={createLinkDocs}
      location={location}
      markdownRemark={data.markdownRemark}
      sectionList={
        /^\/udlejer\//.test(location.pathname)
          ? sectionListUdlejer
          : /^\/lejer\//.test(location.pathname)
          ? sectionListLejer
          : /^\/en\/?$/.test(location.pathname)
          ? sectionListEnglish
          : []
      }
      titlePostfix=" - Lejeloven.dk"
    />
  </Layout>
);

Article.propTypes = {
  data: PropTypes.object.isRequired
};

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        next
        prev
        toc
      }
      fields {
        path
        slug
      }
    }
  }
`;

export default Article;
